<template>
  <div>
    <div v-if="!showLogin">Magic link - {{magicId}} - add spinner here to show trying to process magic link!</div>
    <div v-else>
      <div class="cr-background-sail vh-100" style="overflow: auto">

        <div class="cr-background-sail-overlay vh-100" style="overflow: auto;">

          <div class="float-end pe-2" style="color:#bfbfbf;"><router-link style="color:#bfbfbf;text-decoration: none;" to="pricing"><fai icon="sign-in-alt" fixed-width /> Sign Up Your School</router-link> | <a style="color:#bfbfbf;text-decoration: none;" href="https://admin.sailed.app"><fai icon="user-circle" fixed-width /> Admin</a></div>

          <div class="container-fluid" style="padding-top: 75px;">

            <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style="color:#bfbfbf;">
                <div class="display-5" style="text-align: center;font-weight: bold;">SailEd App</div>
                <!--            <div class="lead" style="line-height: 34px; font-size: 24px;"></div>-->
              </div>
            </div>

            <div v-if="doingLookup" class="row justify-content-center mt-5">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                <div class="text-center tx-submit-spinner fa-2x"><fai icon="spinner" spin/> Please wait while your information is loaded ...</div>
              </div>
            </div>


            <div v-show="magicLinkSent && !doingLookup" class="row justify-content-center mt-5">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                <div class="text-center">
                  <div><fai icon="envelope" class="fa-6x" /></div>
                  <div class="fs-1">Check your email</div>
                </div>
                <div class="text-center mb-2">If <span class="fw-bold fst-italic">{{emailAddress}}</span> is registered with a participating school, a sign in link has been sent.</div>
              </div>
            </div>

            <div v-show="!magicLinkSent && !doingLookup" class="row justify-content-center mt-5">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                <div v-show="invalidMagicLink">
                  <div class="text-center">Oops! The link used is either invalid or expired.</div>
                  <div class="text-center mb-2">Enter the email address registered with your school to receive a new link to access app.</div>
                </div>
                <div v-show="!invalidMagicLink" class="text-center mb-2">Enter the email address registered with your school to receive a link to access app.</div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
                <div class="form-floating mb-4">
                  <input type="email" class="form-control" id="emailAddress" name="emailAddress" v-model="emailAddress" v-bind:class="{'is-valid' : validation.emailAddress, 'is-invalid': validation.emailAddress == false}" v-on:keyup.enter="sendMagicLink" required placeholder="Email Address">
                  <label class="formLabel" for="emailAddress">Email Address</label>
                  <div class="invalid-feedback">Oops! Looks like we forgot something. Please enter a valid email address.</div>
                </div>
              </div>
            </div>

            <div v-show="!magicLinkSent && !doingLookup" class="row pb-5 mb-5">
              <div class="col-12">
                <div class="d-grid gap-2 col-xl-4 col-lg-4 col-md-8 col-sm-8 mx-auto">
                  <button class="btn btn-lg btn-primary text-uppercase p-3"  style="z-index: 100;" v-on:click="sendMagicLink" :disabled="emailAddress == undefined || emailAddress.length <= 0">Send Link</button>
                </div>
              </div>
            </div>

<!--            <div class="row justify-content-center mt-5">-->
<!--              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">-->
<!--                <div style="display:inline-block;width:265px;margin:0 10px;">-->
<!--                  <div class="d-grid gap-2">-->
<!--                    <a class="btn btn-primary text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" href="/9e89f033-76ae-491e-8d96-035a018df65a">Student</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div style="display:inline-block;width:265px;margin:0 10px;">-->
<!--                  <div class="d-grid gap-2">-->
<!--                    <a class="btn btn-outline-light text-uppercase p-3 disabled"  style="z-index: 100;margin-bottom: 5px;" href="#">Instructor</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div style="display:inline-block;width:265px;margin:0 10px;">-->
<!--                  <div class="d-grid gap-2">-->
<!--                    <a class="btn btn-outline-light text-uppercase p-3 disabled"  style="z-index: 100;margin-bottom: 5px;" href="#">Admin</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

          </div>

          <div class="pt-5">
            <div class="footerBlock-dark" style="width: 100%;">
              <hr style="margin:0px;">
              <div style="font-size:10px;padding:10px;color:#ddd">@ Copyright 2023-{{copyYear}}, <a href="https://www.charterrode.com" style="color:#ddd;font-weight:bold;text-decoration: none;">Charter Rode</a>. Weigh anchor. Explore the world. | What is a <a class="smallprint-link" href="/sailing-resume">Sailing Resume</a>?</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {useRoute} from "vue-router"
import api from "../api/api"

export default {
  name: 'Root',
  data() {
    return {
      magicLinkSent: false,
      emailAddress: undefined,
      showLogin: true,
      doingLookup: false,
      magicId: undefined,
      invalidMagicLink: false,
      copyYear: new Date().getFullYear(),
      validation : {
        emailAddress: undefined
      }
    }
  },
  mounted() {
    const route = useRoute()
    let self = this
    // first check to see if we have a magicId present
    // then if we do not, fall back to the localStorage of studentId if this device
    // has been used to access the app
    // otherwise, show the send link prompt!
    self.doingLookup = true
    if ( route.params.magicId && route.params.magicId.length > 0 ) {
      this.magicId = route.params.magicId
      api.getInfoForMagicId(this.magicId).then(userInfo => {
        if (userInfo && userInfo.student) {
          self.$store.commit('setStudentInfo', userInfo)
          window.document.title = userInfo.school.name + ' App'
          self.doingLookup = false
          if ( userInfo.student.viewedGettingStarted === true ) {
            self.$router.push('/home')
          } else {
            self.$router.push('/getting-started')
            // send API request to say we viewed it...
            api.markGettingStartedViewed().then(result => {
              if ( result && result.status && result.status === 'ok' ) {
                // all done!
              }
            })
          }
        } else if (userInfo && userInfo.instructor) {
          // TODO implement me!
          self.doingLookup = false
        } else {
          // failed login
          self.magicId = undefined
          self.invalidMagicLink = true
          self.doingLookup = false
          self.$store.commit('clearAll')
          self.$router.push('/')
        }
      })
    } else if ( this.$store.state.studentId ) {
      api.getInfoForStudentId().then(userInfo => {
        if (userInfo && userInfo.student) {
          self.$store.commit('setStudentInfo', userInfo)
          window.document.title = userInfo.school.name + ' App'
          // conceivably, this is a returning user....
          self.doingLookup = false
          self.$router.push('/home')
        } else {
            // failed login
          self.magicId = undefined
          self.doingLookup = false
          self.$store.commit('clearAll')
          self.$router.push('/')
          }
      })
    } else {
      self.doingLookup = false
      this.showLogin = true
    }
  },
  methods: {
    sendMagicLink() {
      // is the email address valid?
      this.validation.emailAddress = undefined
      if ( this.emailAddress == undefined || this.emailAddress.length < 1 ||
           this.emailAddress.indexOf('@') <= 0 ) {
        this.validation.emailAddress = false
        return
      }
      this.doingLookup = true
      api.sendMagicLink(this.emailAddress).then(response => {
        if ( response && response.status && response.status === 'sent' ) {
          // all good nothing to do but tell the user to check their email..
          this.doingLookup = false
          this.magicLinkSent = true
        } else if (response && response.accounts && response.accounts.length > 1 ) {
          // TODO: show these on-screen to user
          this.doingLookup = false
        } else {
          // failed - we don't want them to know it is not a real account...
          this.doingLookup = false
          this.magicLinkSent = true
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>
.cr-background-sail {
  background-image: url(/sailHome.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color:#ffffff;
  z-index: -1;
  overflow: hidden;
}

a.root {
  color: #bfbfbf;
  text-decoration: none;
  font-weight: bold;
}

a.root:hover {
  color: #ffffff;
}

.cr-background-sail-overlay {
  background-color: rgba(0,0,0,.70);
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  /*z-index: -1;*/
  z-index: 1!important;
}

.cr-logo {
  height: 20px;
}

.formLabel {
  color:#666;
  text-transform: uppercase;
}

.helpBlock {
  font-size:12px;
}

.footerBlock-dark {
  color: #ffffff;
}

.smallprint-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
}

.smallprint-link:hover {
  color: #0d6efd;
  text-decoration: underline;
  font-weight: bolder;
}
</style>
