<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="sailingResumeSetupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Setup Sailing Resume</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">This is the sailing resume setup widget.</div>-->

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">


                  <div v-show="showSailorIdInput" class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="text-align:center"><a href="https://www.charterrode.com" target="_blank"><img src="https://cdn.soflows.app/srb/SRBLogoHorizontal.png" style="max-width:400px;width: 100%;"></a><br/>&nbsp;<br/></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div style="max-width:850px;text-align:left;font-size:20px;font-family:Nunito,Sans-serif;">
                          <div>
                            <p>Congrats! You already have a Charter Rode account and <a href="https://www.charterrode.com/article/sailing-resume" target="_blank">sailing resume</a> set up. We must link your existing Charter Rode account to this app in order to complete this task.</p>
                            <p>Please enter your public Sailor Id from the <a href="https://app.charterrode.com" target="_blank">Charter Rode App</a> below to start the verification process. <strong>Review the <a href="https://www.charterrode.com/article/charter-rode-wallet" target="_blank">Charter Rode Wallet</a> article for a step-by-step guide to locating and copying your public Sailor Id</strong>.</p>
                            <p>For more information about this process, review the Charter Rode detailed guide for <a href="https://www.charterrode.com/article/verifying-charter-rode-wallet-ownership" target="_blank">Wallet Verification</a>.</p>
                            <p>{{school.name}} students receive a $5 credit to their Charter Rode Wallet to pay for logging your entries for this class.</p>
                          </div>
                          <div class="form-floating mb-3">
                            <input type="text" required class="form-control" :class="invalidEntry ? 'is-invalid' : ''" id="sailorIdInputValue" placeholder="Charter Rode Public Sailor Id" v-model="sailorIdInputValue">
                            <label for="sailorIdInputValue">Charter Rode Public Sailor Id</label>
                            <div class="invalid-feedback">You must provide your Sailor Id from Charter Rode to complete this task.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <button class="btn btn-primary taskLinkButton" @click="verifyWallet();$event.target.blur()" :disabled="sailorIdInputValue == undefined">Start Verification</button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 pt-3">
                        <div style="max-width:850px;text-align:left;font-size:16px;font-family:Nunito,Sans-serif;">This process will temporarily navigate you away from this app. You will automatically be returned when verification is complete.</div>
                      </div>
                    </div>
                    <div class="row pt-4">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <button class="btn btn-outline-secondary taskLinkButton" target="_blank" @click="showSailorIdInput = false;$event.target.blur()">Back</button>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div v-show="!showSailorIdInput" class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="text-align:center"><a href="https://www.charterrode.com" target="_blank"><img src="https://cdn.soflows.app/srb/SRBLogoHorizontal.png" style="max-width:400px;width: 100%;"></a><br/>&nbsp;<br/></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                            <div style="max-width:850px;text-align:left;font-size:20px;font-family:Nunito,Sans-serif;">
                              <div v-if="!currentTask.complete && !hasSailingResume">
                                <p>A <a href="https://www.charterrode.com/article/sailing-resume" target="_blank">sailing resume</a> summarizes your relevant boating experience. Your sailing resume is used by insurance companies, banks, charter companies, and others to determine your seaworthiness and overall risk on the water.</p>
<!--                                <p>{{school.name}} students receive a $55 credit from <a href="https://www.charterrode.com" target="_blank">Charter Rode</a> to establish their sailing resume on the <a href="https://www.charterrode.com" target="_blank">Sailing Resume Blockchain</a>. This pays for your required identity validation and logging your entries for this class.</p>-->
                                <p>This class will use your <a href="https://www.charterrode.com" target="_blank">Charter Rode Sailing Resume</a> having your instructor sign-off as the Skipper for the experience you will gain during this course.</p>
                                <p>To get started, tap the <span class="fw-bold fst-italic">Setup My Sailing Resume</span> button below. Check out an <a href="https://sailingresume.io/4081796b-d113-446a-94f2-80eec3d032d2" target="_blank">example sailing resume</a> or the <a href="https://www.charterrode.com/article/quick-start-guide" target="_blank">new user quick start guide</a>.</p>
                                <p>This task will automatically complete when you finish setting up your Sailing Resume. If you already have a sailing resume account with Charter Rode, <a href="#" @click.prevent="$event.target.blur();startVerifyWallet()">tap here</a>.</p>
                              </div>
                              <div v-if="hasSailingResume && !currentTask.complete">
                                <p>A <a href="https://www.charterrode.com/article/sailing-resume" target="_blank">sailing resume</a> summarizes your relevant boating experience. Your sailing resume is used by insurance companies, banks, charter companies, and others to determine your seaworthiness and overall risk on the water.</p>
                                <p>This class will use your <a href="https://www.charterrode.com" target="_blank">Charter Rode Sailing Resume</a> having your instructor sign-off as the Skipper for the experience you will gain during this course. You may also check out the <a href="https://www.charterrode.com/article/quick-start-guide" target="_blank">new user quick start guide</a> for a refresher on the Charter Rode App.</p>
                                <p>{{school.name}} students receive a $5 credit to their Charter Rode Wallet to pay for logging your entries for this class.</p>
                              </div>
                              <div v-if="currentTask.complete">
                                <p>A <a href="https://www.charterrode.com/article/sailing-resume" target="_blank">sailing resume</a> summarizes your relevant boating experience. Your sailing resume is used by insurance companies, banks, charter companies, and others to determine your seaworthiness and overall risk on the water.</p>
                                <p>This class will use your <a href="https://www.charterrode.com" target="_blank">Charter Rode Sailing Resume</a> having your instructor sign-off as the Skipper for the experience you will gain during this course.</p>
                                <p>If you don't already have Charter Rode setup as a mobile app on your phone, check out the article <a href="https://www.charterrode.com/article/add-charter-rode-as-app" target="_blank">How to Add Charter Rode as Mobile App</a>.</p>
                              </div>

                              <div class="pt-3" v-if="currentTask.complete">
                                <p>Below is a video walkthrough of the Charter Rode App and the main features you will be using during your class. You may also check out the <a href="https://www.charterrode.com/article/quick-start-guide" target="_blank">new user quick start guide</a>.</p>
                                <div class="wistia_responsive_padding" style="padding:45% 0 0 0;position:relative;">
                                  <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;max-width:800px;margin:auto;bottom:0;right:0">
                                    <div class="wistia_video_foam_dummy" data-source-container-id="" style="border: 0px; display: block; height: 0px; margin: 0px; padding: 0px; position: static; visibility: hidden; width: auto;"></div>
                                    <iframe src="https://fast.wistia.net/embed/iframe/k8j75lz7nm?seo=true&amp;videoFoam=true" title="Charter Rode App Walkthrough" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen="" width="800" height="450" style="width: 800px; height: 450px;"></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                      </div>
                    </div>

                    <div class="row" v-if="!currentTask.complete && !hasSailingResume">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <a :href="getCRSignUpUrl()" class="btn btn-primary taskLinkButton" @click="$event.target.blur()">Setup My Sailing Resume</a>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="!currentTask.complete && !hasSailingResume">
                      <div class="col-12 pt-3">
                        <div style="max-width:850px;text-align:left;font-size:16px;font-family:Nunito,Sans-serif;">This process will temporarily navigate you away from this app. You will automatically be returned when setup is complete.</div>
                      </div>
                    </div>
<!--                    <div class="row pt-2" v-if="!currentTask.complete && !hasSailingResume">-->
<!--                      <div class="col-12">-->
<!--                        <div class="d-grid gap-2">-->
<!--                          <a class="btn btn-outline-dark taskLinkButton" @click="$event.target.blur();startVerifyWallet()">I Already Have a Charter Rode Account</a>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="row" v-if="!currentTask.complete && hasSailingResume">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <a class="btn btn-primary taskLinkButton" target="_blank" @click="$event.target.blur();addFundsToWallet()">Redeem CRT Credit For This Class</a>
                        </div>
                      </div>
                    </div>

<!--                    <div class="row pt-4" v-if="!currentTask.complete && !hasSailingResume">-->
<!--                      <div class="col-12">-->
<!--                        <div class="d-grid gap-2">-->
<!--                          <a href="https://sailingresume.io/4081796b-d113-446a-94f2-80eec3d032d2" class="btn btn-outline-secondary taskLinkButton" target="_blank" @click="$event.target.blur()">Example Resume</a>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="row pt-1" v-if="!currentTask.complete && !hasSailingResume">-->
<!--                      <div class="col-12">-->
<!--                        <div class="d-grid gap-2">-->
<!--                          <a href="https://www.charterrode.com/article/create-charter-rode-account" class="btn btn-outline-secondary taskLinkButton" target="_blank" @click="$event.target.blur()">Account Setup Guide</a>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->

                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
<!--              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>-->
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import {Modal} from "bootstrap";

const charterRodeAppBaseUrl = process.env.VUE_APP_CHARTER_RODE_APP_URL
const soFlowsAppBaseUrl = process.env.VUE_APP_SOFLOW_BASE_URL
const charterRodeVerifyBaseUrl = process.env.VUE_APP_CHARTER_RODE_VERIFY_APP_URL

export default {
  name: 'SailingResumeSetupModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      markTaskCompleteCalled: false,
      hasSailingResume: this.$store.state.student.sailingResumeId ? true : false,
      school: this.$store.state.school ? this.$store.state.school : undefined,
      showSailorIdInput: false,
      sailorIdInputValue: undefined,
      invalidEntry: false,
      wistiaScript: undefined
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.sailingResumeSetupModal = new Modal(document.getElementById('sailingResumeSetupModal'))
      let viewCourseScheduleEL = document.getElementById('sailingResumeSetupModal')
      let self = this
      self.$store.state.activeModal = this.sailingResumeSetupModal
      viewCourseScheduleEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.sailingResumeSetupModal.toggle()
      this.currentTask = this.$store.state.currentTask


      if ( this.wistiaScript == undefined ) {

        this.wistiaScript = document.createElement('script')
        this.wistiaScript.setAttribute('src', 'https://fast.wistia.net/assets/external/E-v1.js')
        this.wistiaScript.setAttribute('async', 'true')
        document.head.appendChild(this.wistiaScript)
      }
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.sailingResumeSetupModal.hide()
    },
    startVerifyWallet() {
      // first we have to prompt the user for their Sailor Id from Charter Rode/Sailing Resume Blockchain
      this.showSailorIdInput = true
      setTimeout(function() {
        document.getElementById('sailorIdInputValue').focus()
      }, 500)
    },
    verifyWallet() {
      this.invalidEntry = false
      if ( !this.sailorIdInputValue ) {
        this.invalidEntry = true
        return
      }
      // this is the hook to kick off wallet verification to verify the ID of the current user on the Sailing Resume Blockchain
      let useSailorId = this.sailorIdInputValue
      // now verify the entry
      let verifyPayload = {
        "nonce":crypto.randomUUID(),
        "ts":Math.round(new Date().getTime() / 1000),
        "sailorId":useSailorId,
        "orgName":this.$store.state.school.name,
        "appName":"Student App",
        "appUrl":soFlowsAppBaseUrl,
        "appDesc":'The '+this.$store.state.school.name+' app keeps all your sailing class materials conveniently organized into an online app.',
        "appLogo":this.$store.state.school.logoUrl,
        "redirectUrl":this.getWalletVerifyRedirectURL(),
        "purpose":'The purpose of this request is to link your Sailing Resume with the '+this.$store.state.school.name+' app. Your sailing resume will be used in our sailing classes to log your experience.'
      }
      // stringify the object
      let signingPayloadStr = JSON.stringify(verifyPayload)
      localStorage.setItem('sailingResumeVerificationPayload', signingPayloadStr)

      // base64 encode
      let base64EncodedPayload = btoa(signingPayloadStr)
      window.location = charterRodeVerifyBaseUrl +'/'+base64EncodedPayload
      // alert(JSON.stringify(verifyPayload))
      // this.markTaskComplete()
    },
    addFundsToWallet() {
      // this is where we use their existing sailorId on the sailing resume blockchain to credit their wallets
      // 5 CRT for logging this class
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask, {awardCRT: true })
      this.sailingResumeSetupModal.hide()
    },
    getCRSignUpUrl() {
      if ( this.school && this.school.crVoucherCode ) {
        return charterRodeAppBaseUrl + '/sign-up?v=' + this.school.crVoucherCode + '&r=' + encodeURIComponent(soFlowsAppBaseUrl + '/sailingResume')
      } else {
        return charterRodeAppBaseUrl + '/sign-up?r=' + encodeURIComponent(soFlowsAppBaseUrl + '/sailingResume')
      }
    },
    getWalletVerifyRedirectURL() {
      return soFlowsAppBaseUrl+'/sailingResume'
    }
  },
  components: {

  }
}
</script>

<style>

</style>
