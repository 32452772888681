<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="courseHandoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Course Handout</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div><div class="fw-bold fs-3">{{currentTask.handoutTitle}}</div></div>
                        <div style="max-width:850px;text-align:left;font-size:20px;">
                          <div v-html="currentTask.handoutDescription"></div>
                          <div class="pt-3">You may mark this task complete after tapping or clicking the <span class="fw-bold fst-italic">Download PDF</span> button below. Only mark this task complete if you have completed the review of the entire document!</div>
                        </div>
                      </div>
                    </div>

                    <div class="row pt-3">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <a :href="currentTask.pdfDownloadUrl" class="btn btn-primary taskLinkButton" target="_blank" @click="$event.target.blur();viewedPDF=true">Download PDF</a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete" :disabled="!viewedPDF">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
  name: 'CourseHandoutModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      markTaskCompleteCalled: false,
      viewedPDF: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.courseHandoutModal = new Modal(document.getElementById('courseHandoutModal'))
      let courseHandoutModalEL = document.getElementById('viewCourseScheduleModal')
      let self = this
      self.$store.state.activeModal = this.courseHandoutModal
      courseHandoutModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.courseHandoutModal.toggle()
      this.currentTask = this.$store.state.currentTask
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.courseHandoutModal.hide()
    },
    clickedPDF() {

    }
  },
  components: {

  }
}
</script>

<style>

</style>
