<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>Resources</h4>
          <div class="charterRodeHelpText">We hope that you find these sailing and boating resources educational, interesting, and helpful.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="cr-art-section-container">
            <ul>
              <li v-for="article in resources" :key="article.id"><a href="#" @click.prevent.stop="showArticle(article.id)" class="cr-art-link">{{article.title}}</a></li>
            </ul>
          </div>
        </div>
      </div>


      <div class="row justify-content-center mt-5">
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
          <div style="display:inline-block;width:265px;margin:0 10px;">
            <div class="d-grid gap-2">
              <router-link class="btn btn-primary text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" to="/home">Back to My Tasks</router-link>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'Resources',
  data() {
    return {
      resources: this.$store.state.resourceArticles,
    }
  },
  mounted() {

  },
  methods: {
    showArticle(articleId) {
      let theArticle = undefined
      this.resources.forEach(function(anArticle) {
        if ( anArticle.id === articleId ) {
          theArticle = anArticle
        }
      })

      if ( theArticle ) {
        this.currentArticle = theArticle
        this.$store.state.currentArticle = theArticle
        // pass this along to the subview!
        this.$router.push('/viewResource')
      }
    }
  },
  components: {
  }
}
</script>

<style>

.cr-art-section-container {
  color: #888;
  font-size:1.2em;
}

.cr-art-section-header {
  font-weight: 700;
  font-size: 18px;
  color: #444
}

.cr-art-link {
  text-decoration: none;
  color: #888
}
.cr-art-link-title {
  text-decoration: none;
  color: #444;
  font-weight: 700;
}

.cr-art-link:hover {
  color: #0d6efd;
  text-decoration: underline
}

ol.text-list>li,
ul.text-list>li {
  margin-top: 1em
}

.text-list-bold,
.text-list-header {
  font-weight: 700
}

</style>
