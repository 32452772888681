<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="contentBlockModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{currentTask.title}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>-->

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="max-width:850px;text-align:left;font-size:20px;">
                          <div v-if="currentEnrollment">
                            <div v-html="currentTask.content"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
  name: 'ContentBlockModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentEnrollment: this.$store.state.currentEnrollment ? this.$store.state.currentEnrollment : undefined,
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      markTaskCompleteCalled: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.contentBlockModal = new Modal(document.getElementById('contentBlockModal'))
      let contentBlockModalEL = document.getElementById('contentBlockModal')
      let self = this
      self.$store.state.activeModal = this.contentBlockModal
      contentBlockModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.contentBlockModal.toggle()
      this.currentTask = this.$store.state.currentTask
      this.currentEnrollment = this.$store.state.currentEnrollment
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.contentBlockModal.hide()
    },
  },
  components: {

  }
}
</script>

<style>

</style>
