<template>
  <div>
    <div class="cr-background-sail vh-100" style="overflow: auto">
      <div class="cr-background-sail-overlay vh-100" style="overflow: auto;">

        <div class="float-end pe-2" style="color:#bfbfbf;"><router-link style="color:#bfbfbf;text-decoration: none;" to="/"><fai icon="user-circle" fixed-width /> Student Login</router-link></div>

        <div style="padding-top: 75px;">

          <div style="text-align:center">
            <div style="margin:0px auto;max-width:850px;">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style="color:#bfbfbf;">
                    <div class="display-5" style="text-align: center;font-weight: bold;">SailEd App Pricing</div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <div style="max-width:850px;text-align:left;font-size:20px;">
                      <div>
                        <p>Interested in upgrading your student experience? <a href="mailto:info@charterrode.com?subject=SailEd%20App">Email us</a> with your information and we can setup a time to discuss. In the meantime, take a look at pricing options below.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <div style="max-width:850px;text-align:left;font-size:20px;">
                      <div style="backdrop-filter: blur(10px);background-color: rgba(255,255,255,.1);border-radius: 25px;">
                        <div style="text-align:center;font-weight: bold;font-size: 2em;">Crew</div>
                        <div class="border-bottom" style="padding-left: 5px;"><span style="text-decoration: line-through">$825 USD</span> FREE annual subscription</div>
                        <ul>
                          <li>Class task list for ASA 101</li>
                          <li>Standard welcome email</li>
                          <li>Student reminder emails</li>
                          <li>Standard listing on <a class="pricing-link" href="https://sailingschools.app" target="_blank">SailingSchools.app</a></li>
                        </ul>
                        <div class="text-center mt-1 mb-3 pb-3 fw-bold fs-4">FREE for All Schools</div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="d-grid gap-2 col-xl-10 col-lg-10 col-md-10 col-sm-10 mx-auto">
                            <a href="mailto:info@charterrode.com?subject=SailEd%20App%20Crew" class="btn btn-lg btn-primary text-uppercase p-3"  style="z-index: 100;">Contact Us</a>
                          </div>
                        </div>
                      </div>

                      <div style="backdrop-filter: blur(10px);background-color: rgba(255,255,255,.1);border-radius: 25px;">
                        <div class="mt-5" style="text-align:center;font-weight: bold;font-size: 2em;">First Mate</div>
                        <div class="border-bottom" style="padding-left: 5px;">$7,500 USD annual subscription</div>
                        <ul>
                          <li>Core ASA class task lists (ASA 101,102,103,104,106,114)</li>
                          <li>School specific FAQs</li>
                          <li>School specific welcome email</li>
                          <li>Student reminder emails</li>
                          <li>Up to three (3) school specific emails before or after class start date</li>
                          <li>Integrated and automated student registration with existing website</li>
                          <li>Premium listing on <a class="pricing-link" href="https://sailingschools.app" target="_blank">SailingSchools.app</a> (Limited Availability)</li>
                        </ul>

                        <div class="text-center mt-1 mb-3 pb-3 fw-bold fs-4">$5,000 USD Annually for Qualifying Schools</div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="d-grid gap-2 col-xl-10 col-lg-10 col-md-10 col-sm-10 mx-auto">
                            <a href="mailto:info@charterrode.com?subject=SailEd%20App%20First%20Mate" class="btn btn-lg btn-primary text-uppercase p-3"  style="z-index: 100;">Contact Us</a>
                          </div>
                        </div>
                      </div>

                      <div style="backdrop-filter: blur(10px);background-color: rgba(255,255,255,.1);border-radius: 25px;">
                        <div class="mt-5" style="text-align:center;font-weight: bold;font-size: 2em;">Skipper</div>
                        <div class="border-bottom" style="padding-left: 5px;">$12,500 USD annual subscription</div>
                        <ul>
                          <li>All First Mate Features+</li>
                          <li>Customize core ASA class task lists to add school specific resources or requirements</li>
                          <li>Custom task lists for additional ASA/non ASA courses, instructor onboarding, boat orientation, or other workflows</li>
                          <li>Unlimited school specific emails before or after class start date</li>
                          <li>White glove onboarding - we take your information and load it into the app for you</li>
                          <li>Integrated and automated student registration with existing website</li>
                        </ul>

                        <div class="text-center mt-1 mb-3 pb-3 fw-bold fs-4">$7,500 USD Annually for Qualifying Schools</div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="d-grid gap-2 col-xl-10 col-lg-10 col-md-10 col-sm-10 mx-auto">
                            <a href="mailto:info@charterrode.com?subject=SailEd%20App%20Skipper" class="btn btn-lg btn-primary text-uppercase p-3"  style="z-index: 100;">Contact Us</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div style="text-align:center" class="mt-5">
            <div style="margin:0px auto;max-width:850px;">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style="color:#bfbfbf;">
                    <div class="display-5" style="text-align: center;font-weight: bold;">Demo Account</div>
                  </div>
                </div>
                <div v-if="!demoLinkSent" class="row mt-3">
                  <div class="col-12">
                    <div style="max-width:850px;text-align:left;font-size:20px;">
                      <div>
                        <p>Interested taking the SailEd App for a test sail? Register for the demo school ASA 101 course below.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container" >
            <div v-if="demoLinkSent" class="row justify-content-center mt-3">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                <div class="text-center">
                  <div><fai icon="envelope" class="fa-6x" /></div>
                  <div class="fs-1">Check your email</div>
                </div>
                <div class="text-center mb-2">Successfully registered <span class="fw-bold fst-italic">{{emailAddress}}</span> with the demo school, a sign in link has been sent.</div>
              </div>
            </div>

            <div v-if="!demoLinkSent">
              <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                  <div class="text-center mb-2">Enter your name and email address to receive a link to access the demo SailEd App.</div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div class="form-floating mb-4">
                    <input type="text" class="form-control" id="firstName" name="firstName" v-model="firstName" v-bind:class="{'is-valid' : validation.firstName, 'is-invalid': validation.firstName == false}" required placeholder="First Name">
                    <label class="formLabel" for="firstName">First Name</label>
                    <div class="invalid-feedback">Please enter your first name.</div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div class="form-floating mb-4">
                    <input type="text" class="form-control" id="lastName" name="lastName" v-model="lastName" v-bind:class="{'is-valid' : validation.lastName, 'is-invalid': validation.lastName == false}" required placeholder="Last Name">
                    <label class="formLabel" for="lastName">Last Name</label>
                    <div class="invalid-feedback">Please enter your last name.</div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="form-floating mb-4">
                    <input type="email" class="form-control" id="emailAddress" name="emailAddress" v-model="emailAddress" v-bind:class="{'is-valid' : validation.emailAddress, 'is-invalid': validation.emailAddress == false}" required placeholder="Email Address">
                    <label class="formLabel" for="emailAddress">Email Address</label>
                    <div class="invalid-feedback">Oops! Looks like we forgot something. Please enter a valid email address.</div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!demoLinkSent" class="row pb-5 mb-5">
              <div class="col-12">
                <div class="d-grid gap-2 col-xl-4 col-lg-4 col-md-8 col-sm-8 mx-auto">
                  <button class="btn btn-lg btn-primary text-uppercase p-3"  style="z-index: 100;" v-on:click="sendDemoLink" :disabled="emailAddress == undefined || emailAddress.length <= 0">Send Link</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="footerBlock-dark" style="width: 100%;">
            <hr style="margin:0px;">
            <div style="font-size:10px;padding:10px;color:#ddd">@ Copyright 2023-{{copyYear}}, <a href="https://www.charterrode.com" style="color:#ddd;font-weight:bold;text-decoration: none;">Charter Rode</a>. Weigh anchor. Explore the world. | What is a <a class="smallprint-link" href="/sailing-resume">Sailing Resume</a>?</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/api";

export default {
  name: 'Pricing',
  data() {
    return {
      demoLinkSent: false,
      copyYear: new Date().getFullYear(),
      firstName: undefined,
      lastName: undefined,
      emailAddress: undefined,
      sendingDemoLink: false,
      validation: {
        firstName: undefined,
        lastName: undefined,
        emailAddress: undefined
      }
    }
  },
  mounted() {
  },
  methods: {
    sendDemoLink() {
      // is the email address valid?
      this.validation.firstName = undefined
      this.validation.lastName = undefined
      this.validation.emailAddress = undefined
      let hasErrors = false
      if ( this.firstName == undefined || this.firstName.length < 1 ) {
        this.validation.firstName = false
        hasErrors = true
      }
      if ( this.lastName == undefined || this.lastName.length < 1 ) {
        this.validation.lastName = false
        hasErrors = true
      }
      if ( this.emailAddress == undefined || this.emailAddress.length < 1 ||
          this.emailAddress.indexOf('@') <= 0 ) {
        this.validation.emailAddress = false
        hasErrors = true
      }
      if ( hasErrors ) {
        return
      }
      this.sendingDemoLink = true

      api.sendDemoLink(this.firstName, this.lastName, this.emailAddress).then(response => {
        if ( response && response.status && response.status === 'ok' ) {
          // all good nothing to do but tell the user to check their email..
          this.demoLinkSent = true
          this.sendingDemoLink = false
        } else {
          // failed - show some sort of error message
          // TODO: implement me!
          this.sendingDemoLink = false
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>
table.pricing-table td,
table.pricing-table th {
  background: transparent!important;
}

.pricing-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bolder;
}

.pricing-link:hover {
  color: #0d6efd;
  text-decoration: underline;
  font-weight: bolder;
}
.smallprint-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
}

.smallprint-link:hover {
  color: #0d6efd;
  text-decoration: underline;
  font-weight: bolder;
}
</style>

