<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="viewCourseScheduleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{currentTask.title}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">Find below the details about this course. Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>-->

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="max-width:850px;text-align:left;font-size:20px;">
                          <div v-if="currentEnrollment">

                            <div class="mb-5" v-if="currentTask.overviewContent" v-html="currentTask.overviewContent"></div>

                            <div><span class="fw-bold fs-5">When</span></div>
                            <div>You are scheduled for <span class="fw-bold">{{currentEnrollment.courseTitle}}</span> on <span class="fw-bold" v-html="formatCourseDates(currentEnrollment, currentEnrollment.startDate, currentEnrollment.endDate)"></span>.</div>
                            <div v-html="currentTask.whenContent"></div>


                            <div class="mt-5"><span class="fw-bold fs-5">Where</span></div>
                            <div v-html="currentTask.whereContent"></div>

                            <div class="mt-5" v-if="currentTask.contactContent" v-html="currentTask.contactContent"></div>

                            <div v-if="windyAppSpotId" class="mt-5"><span class="fw-bold fs-5">Weather Forecast</span></div>
                            <div v-if="windyAppSpotId" style="border: 1px solid #666;"><div data-windywidget="forecast" data-thememode="white" data-windunit="knots" data-tempunit="F" :data-spotid="windyAppSpotId" data-appid="widgets_9ee08499a3"></div></div>
                            <div v-if="windyAppSpotId" class="mt-3">The team at WINDY.APP provide {{school.name}} students with a free month of WINDY.APP Pro. <a href="https://windy.app/procode?code=LEARN2SAIL" target="_blank">Tap here to activate the LEARN2SAIL Pro Code</a> for your WINDY.APP account. If you do not yet have an account, you can set it up while activating the code.</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import {Modal} from "bootstrap"

export default {
  name: 'CourseScheduleModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      currentEnrollment: this.$store.state.currentEnrollment ? this.$store.state.currentEnrollment : undefined,
      windyAppSpotId: this.$store.state.school.windyAppSpotId ? this.$store.state.school.windyAppSpotId : undefined,
      school: this.$store.state.school ? this.$store.state.school : undefined,
      markTaskCompleteCalled: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.viewCourseScheduleModal = new Modal(document.getElementById('viewCourseScheduleModal'))
      let viewCourseScheduleEL = document.getElementById('viewCourseScheduleModal')
      let self = this
      self.$store.state.activeModal = this.viewCourseScheduleModal
      viewCourseScheduleEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
        // cleanup weather stuff
        // try {
        //   document.head.removeChild(self.weatherScript)
        //   self.weatherScript = undefined
        //   for (let aChild of document.head.children) {
        //     if (aChild.getAttribute('href') && aChild.getAttribute('href').startsWith('//windy.app')) {
        //       document.head.removeChild(aChild)
        //     }
        //   }
        // } catch (err){}
      })
      this.viewCourseScheduleModal.toggle()
      this.currentTask = this.$store.state.currentTask
      this.currentEnrollment = this.$store.state.currentEnrollment


      if ( this.weatherScript == undefined ) {

        this.weatherScript = document.createElement('script')
        this.weatherScript.setAttribute('src', 'https://windy.app/widgets-code/forecast/windy_forecast_async.js?v1.4.14')
        this.weatherScript.setAttribute('async', 'true')
        document.head.appendChild(this.weatherScript)
      }

    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.viewCourseScheduleModal.hide()
    },
    formatCourseDates(enrollment, startDate, endDate) {
      // trip.date is in format YYYY-MM-DD
      const monthsOfYear = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let tripStart = startDate.split('-')
      let tripEnd = endDate.split('-')
      if ( enrollment.courseDurationDays === 1 ) {
        return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2]
      } else {
        if (tripStart[0] === tripEnd[0]) { // years match
          if (tripStart[1] === tripEnd[1]) { // months match
            return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2] + '-' + tripEnd[2] + ', ' + tripStart[0]
          } else { // months mismatch
            return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2] + '-' + monthsOfYear[parseInt(tripEnd[1])] + ' ' + tripEnd[2] + ', ' + tripStart[0]
          }
        } else { // years mismatch...
          return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2] + ', ' + tripStart[0] + '-' + monthsOfYear[parseInt(tripEnd[1])] + ' ' + tripEnd[2] + ', ' + tripEnd[0]
        }
      }
    },
  },
  components: {

  }
}
</script>

<style>

</style>
