<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="taskModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Generic Task</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>



            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>

import {Modal} from "bootstrap";

export default {
  name: 'TaskModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      markTaskCompleteCalled: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.taskModal = new Modal(document.getElementById('taskModal'))
      let taskModalEL = document.getElementById('taskModal')
      let self = this
      self.$store.state.activeModal = this.taskModal
      taskModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.taskModal.toggle()
      this.currentTask = this.$store.state.currentTask
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.taskModal.hide()
    },
  },
  components: {

  }
}
</script>

<style>

</style>
