<template>
  <div>
    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="asaAccountSetupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Setup ASA Account</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>-->

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="text-align:center"><a href="https://asa.com" target="_blank"><img src="https://cdn.soflows.app/asa/americanSailingLogo.jpg" style="max-width:400px;width: 100%;"></a><br/>&nbsp;<br/></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div style="max-width:850px;text-align:left;font-size:20px;">
                          <form>
                            <div v-if="!currentTask.complete">
                              <p>We are an <a href="https://asa.com" target="_blank">American Sailing</a>&nbsp;(ASA) school. Your certification for this course will be processed by ASA after we submit your final score for the course.</p>
                              <p>American Sailing requires you to setup your ASA ID number directly with them. Please follow the steps below to complete.</p>
                              <p>Once you have your ASA issued ID number, you may enter it below and then tap or click the <span class="fw-bold fst-italic">Set My ASA ID</span> button below to complete this task.</p>
                              <ol>
                                <li>Go to the <a href="https://members.asa.com/asid/register.php" target="_blank">American Sailing registration page</a>.</li>
                                <li>Provide your first name, last name, and email address in the form.</li>
                                <li>Check your email, follow the steps, and copy or type your American Sailing ID below.</li>
                                <li><strong>Be sure to add your current physical address so ASA can send your logbook seals after certification</strong>.</li>
                              </ol>
                            </div>
                            <div v-if="currentTask.complete">
                              <p>We are an <a href="https://asa.com" target="_blank">American Sailing</a>&nbsp;(ASA) school. Your certification for this course will be processed by ASA after we submit your final score for the course.</p>
                              <p>Congrats! You have successfully registered your American Sailing ID with {{school.name}}.</p>
                            </div>

                            <div class="form-floating mb-5">
                              <input type="number" required class="form-control" pattern="[0-9]*" :class="invalidEntry ? 'is-invalid' : ''" id="asaMemberNumber" placeholder="1234567890" v-model="asaMemberNumber" :disabled="currentTask.complete">
                              <label for="asaMemberNumber">American Sailing ID #</label>
                              <div class="invalid-feedback">You must provide a valid ASA member number to complete this task. The format is a 10 digit number.</div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="!currentTask.complete">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <button class="btn btn-primary taskLinkButton" @click="markTaskComplete();$event.target.blur()" :disabled="asaMemberNumber == undefined">Set My ASA ID</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
<!--              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>-->
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
  name: 'ASAAccountSetupModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      markTaskCompleteCalled: false,
      invalidEntry: false,
      asaMemberNumber: this.$store.state.student.asaMemberId ? this.$store.state.student.asaMemberId : undefined,
      school: this.$store.state.school ? this.$store.state.school : undefined
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.invalidEntry = false
      this.asaAccountSetupModal = new Modal(document.getElementById('asaAccountSetupModal'))
      let asaAccountSetupModalEL = document.getElementById('asaAccountSetupModal')
      let self = this
      self.$store.state.activeModal = this.asaAccountSetupModal
      asaAccountSetupModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.asaAccountSetupModal.toggle()
      this.currentTask = this.$store.state.currentTask
    },
    markTaskComplete() {
      this.invalidEntry = false
      if ( !this.asaMemberNumber ) {
        this.invalidEntry = true
        return
      }
      this.$store.state.student.asaMemberId = this.asaMemberNumber
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask, {asaMemberId: this.asaMemberNumber })
      this.asaAccountSetupModal.hide()
    },
  },  components: {

  }
}
</script>

<style>

</style>
