<template>
  <div class="container">
    <div class="row">
      <h4>Sign Out</h4>
      <div>If you are not automatically signed out in five seconds, <a href="#" v-on:click="setLoggedOut">tap or click here</a>.</div>
    </div>
  </div>
</template>

<script>
import api from "../api/api";

export default {
  name: 'SignOut',
  components: {

  },
  mounted() {
    // as soon as this view is loaded, logout!
    this.setLoggedOut();
  },
  methods: {
    setLoggedOut() {
      api.signOut().then(response => {
        if ( response && response.status === 'loggedOut' ) {
          this.$store.commit('clearAll')
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<style>

</style>

