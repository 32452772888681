<template>
  <div>
    <div class="container">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4>Profile</h4>
          <div class="charterRodeHelpText">Update your name and reminder email preferences below. If you need to change your email address please contact {{school.name}} at <a :href="'mailto:'+school.schoolEmail">{{school.schoolEmail}}</a>.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="cr-art-section-container">
            <div class="row">
              <div class="col-md-6" style="margin: 0 auto;">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.firstNameBlurred && validFirstName, 'is-invalid': validation.firstNameBlurred && !validFirstName}" @blur="validation.firstNameBlurred = true" v-model="firstName" id="firstName" name="firstName" placeholder="Art" required>
                  <label for="firstName">First Name</label>
                  <div class="invalid-feedback">Please let us know your name.</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" style="margin: 0 auto;">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.lastNameBlurred && validLastName, 'is-invalid': validation.lastNameBlurred && !validLastName}" @blur="validation.lastNameBlurred = true" v-model="lastName" id="lastName" name="lastName" placeholder="Vandelay" required>
                  <label for="lastName">Last Name</label>
                  <div class="invalid-feedback">Please let us know your name.</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" style="margin: 0 auto;">
                <div class="form-floating mb-3">
                  <input type="email" class="form-control is-valid" v-model="student.email" id="email" name="email" placeholder="art@vandelay.com" required disabled>
                  <label for="email">Email Address</label>
                  <div class="invalid-feedback">Please confirm your email address.</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" style="margin: 0 auto;">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" v-bind:class="{'is-valid' : validation.phoneBlurred && validPhone, 'is-invalid': validation.phoneBlurred && !validPhone}" v-model="phone" @blur="validation.phoneBlurred = true" id="phone" name="phone" placeholder="(555) 867-5309">
                  <label for="phone">Phone</label>
                  <div class="invalid-feedback">Please confirm your phone number.</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" style="margin: 0 auto;">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="reminderEmailsChecked" name="sendReminderEmails" checked v-model="reminderEmailsOn" @change="confirmDisableReminders">
                  <label class="form-check-label charterRodeBtn" for="reminderEmailsChecked">Send reminder emails about my classes</label>
                </div>
                <div v-if="!reminderEmailsOn" id="sendReminderEmailsHelpBlock" class="form-text" v-bind:class="[!reminderEmailsOn ? 'text-danger' : '']">Reminder emails about your upcoming classes are disabled. If you are not prepared for a class, you may be subject to a rescheduling fee to not hold back other students.<br>&nbsp;<br><strong>We highly recommend turning on these reminders so that you do not forget about your class</strong>.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-5">
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
          <div style="display:inline-block;width:265px;margin:0 10px;">
            <div class="d-grid gap-2">
              <button id="updateProfileButton" class="btn btn-lg btn-outline-primary text-uppercase" :class="{'disabled': isUpdateProfileDisabled}" v-on:click="sendUpdateProfile">Update My Profile</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-toast">
      <div id="profileUpdateToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Profile Updated!</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">Your profile has been updated</div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-toast">
      <div id="profileUpdateErrorToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Update Profile Error</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">There was an unknown error, please try again later</div>
      </div>
    </div>
  </teleport>

</template>

<script>

import api from "../../api/api";
import {Toast} from "bootstrap";

export default {
  name: 'Resources',
  data() {
    return {
      student: this.$store.state.student ? this.$store.state.student : {},
      school: this.$store.state.school ? this.$store.state.school : undefined,
      profileChanged: false,
      reminderEmailsOn: this.$store.state.student ? this.$store.state.student.reminderEmailsOn : '',
      firstName: this.$store.state.student ? this.$store.state.student.firstName : '',
      lastName: this.$store.state.student ? this.$store.state.student.lastName : '',
      phone: this.$store.state.student ? this.$store.state.student.phone : '',
      validation: {
        emailBlurred: false,
        phoneBlurred: false,
        firstNameBlurred: false,
        lastNameBlurred: false

      }
    }
  },
  mounted() {

  },
  methods: {
    validateTextInput(anInput) {
      this.profileChanged = true
      if ( !anInput ) {
        return false;
      } else if ( anInput === '' ) {
        return false;
      }
      return true;
    },
    sendUpdateProfile() {
      if ( this.firstName !== undefined && this.firstName.length > 0 &&
          this.lastName !== undefined && this.lastName.length > 0) {
        // okay do the update!
        let payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          reminderEmailsOn: this.reminderEmailsOn
        }

        let self = this
        api.updateProfile(payload).then(result => {
          if ( result && result.status && result.status === 'ok' ) {
            console.log('profile updated')
            // update our in-memory store....
            self.$store.state.student.firstName = self.firstName
            self.$store.state.student.lastName = self.lastName
            self.$store.state.student.phone = self.phone
            self.$store.state.student.reminderEmailsOn = self.reminderEmailsOn
            // launch Toast notification...
            let profileUpdatedToast = new Toast(document.getElementById('profileUpdateToast'))
            profileUpdatedToast.show()
          } else {
            // error
            let profileUpdatedToast = new Toast(document.getElementById('profileUpdateErrorToast'))
            profileUpdatedToast.show()
            console.log('server reported back error when updating profile')
          }
        })
      }
      document.getElementById('updateProfileButton').blur()
    },
    confirmDisableReminders() {
      if ( !this.reminderEmailsOn ) {
        let confirmDisableReminderEmails = confirm("Are you sure you want to disable reminder emails?")
        this.reminderEmailsOn = !confirmDisableReminderEmails
        if ( !this.reminderEmailsOn ) {
          this.profileChanged = true
        }
      } else {
        this.profileChanged = true
      }

      // try to loose focus
      document.getElementById('reminderEmailsChecked').blur()
    },
    setProfileChanged(val) {
      this.profileChanged = val
    }
  },
  computed: {
    isUpdateProfileDisabled: function() {
      return !this.profileChanged
    },
    validFirstName: function() {
      return this.validateTextInput(this.firstName)
    },
    validLastName: function() {
      return this.validateTextInput(this.lastName)
    },
    validPhone: function() {
      this.setProfileChanged(true)
      return true
    }
  },
  components: {

  }
}
</script>

<style>

.cr-art-section-container {
  color: #888;
  font-size:1.2em;
}

.cr-art-section-header {
  font-weight: 700;
  font-size: 18px;
  color: #444
}

.cr-art-link {
  text-decoration: none;
  color: #888
}
.cr-art-link-title {
  text-decoration: none;
  color: #444;
  font-weight: 700;
}

.cr-art-link:hover {
  color: #0d6efd;
  text-decoration: underline
}

ol.text-list>li,
ul.text-list>li {
  margin-top: 1em
}

.text-list-bold,
.text-list-header {
  font-weight: 700
}

</style>
