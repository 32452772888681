import axios from "axios";

const apiBase = process.env.VUE_APP_SOFLOW_API_ENDPOINT

function processErrorResponse(errorDetail) {
    console.log(errorDetail)
    // alert(errorDetail.message)
}

let api = {

    sendDemoLink: async (aFirstName, aLastName, anEmail) => {
        try {

            const response = await axios({
                withCredentials: true,
                headers: {'Authorization': 'f75ce0d9-cb88-42b8-b1e0-6d54677b02a5'},
                method: 'post',
                url: apiBase + '/sendDemoLink',
                data: {
                    firstName: aFirstName,
                    lastName: aLastName,
                    email: anEmail
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getInfoForMagicId: async (magicId) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/magicLink/' + magicId
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getInfoForStudentId: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/student'
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
                return response.data.returnObject
            }
        } catch (error) {
            console.log(error);
        }
    },

    sendMagicLink: async (anEmail) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/sendMagicLink/' + anEmail
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    markGettingStartedViewed: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/student/markGettingStartedViewed'
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    markTaskComplete: async (taskId, data) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/student/markTaskComplete',
                data: {
                    taskId: taskId,
                    info: data ? data: undefined
                }
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    updateProfile: async (data) => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'post',
                url: apiBase + '/student/update',
                data: data
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    signOut: async () => {
        try {
            const response = await axios({
                withCredentials: true,
                method: 'get',
                url: apiBase + '/signOut',
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    lookupSailingResumeFor: async (aSailingResumeId) => {
        try {
            const response = await axios({
                method: 'get',
                url: 'https://api.charterrode.com/sailorResume/'+aSailingResumeId,
            });
            if (response.data.responseType === "SUCCESS") {
                return response.data.returnObject
            } else if (response.data.responseType === "ERROR") {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    }
}
export default api