<template>
  <div class="container" v-if="renderComponent">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Welcome {{student.firstName}}!</h4>
        <div class="charterRodeHelpText">Find below the courses you have signed up for and the required tasks to be completed before you arrive at the dock on the first day of class. To view the details of a task, tap or click anywhere on the task. Once you complete each task, mark it complete to let your instructor know you are ready for the first day of class.</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-if="hasEnrollments && completedEnrollments && completedEnrollments.length > 0" class="pb-1 fw-bold fs-5">Upcoming Classes</div>
        <div v-if="hasEnrollments && (!enrollments || enrollments.length <= 0)">You have no upcoming classes.</div>
        <div v-if="!hasEnrollments && school && school.noEnrollmentsMessage" class="text-center" v-html="school.noEnrollmentsMessage">
        </div>
        <div v-if="hasEnrollments" class="accordion" id="portalHome">

          <div class="accordion-item" v-for="(enrollment) in enrollments" :key="enrollment.id">
            <h2 class="accordion-header">
              <button class="accordion-button" :class="enrollment.id === currentExpandedEnrollmentId ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="'#enrollment-'+enrollment.id" aria-expanded="true" :aria-controls="'enrollment-'+enrollment.id" @click="$event.target.blur();setCurrentlyExpandedEnrollment(enrollment)">
                {{enrollment.courseTitle}}&nbsp;<span class="text-dark">(<span v-html="formatCourseDates(enrollment, enrollment.startDate,enrollment.endDate)"></span>)</span>
              </button>

              <div class="progress" role="progressbar" aria-label="tasks percent complete" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="height: 3px;">
                <div class="progress-bar bg-success" :style="'width:'+ enrollment.percentComplete+'%;'"></div>
              </div>
            </h2>
            <div :id="'enrollment-'+enrollment.id" class="accordion-collapse collapse" :class="enrollment.id === currentExpandedEnrollmentId ? 'show' : ''" data-bs-parent="#portalHome">
              <div class="accordion-body">

                <div><span class="class-start-header"><span v-html="daysUntilClassStarts(enrollment.startDate)"></span></span><span v-html="weeksUntilClassStarts(enrollment.startDate)"></span></div>
                <div>{{enrollment.courseOverview}}</div>

                <div class="pt-3 pb-3 fs-4" v-if="enrollment.allTasksComplete">Congrats! You have completed all tasks and are ready for your sailing class, great job {{student.firstName}}!</div>

                <div class="row" v-for="task in enrollment.tasks" :key="task.id" @click="loadTaskDetail(task, enrollment)" :style="task.complete ? 'opacity: .6':''">
                  <div class="col-12">
                    <div class="card mt-3 bg-light">
                      <div class="card-body" :class="task.hasDetail ? 'charterRodeBtn' : ''">
                        <div style="float: right;position: relative;top:1px;font-weight: bold;text-transform: uppercase;"><fai :icon="task.complete ? 'check' : 'exclamation-triangle'" :class="task.complete ? 'text-success' : 'text-danger'" /></div>
                        <table :class="task.complete ? 'text-success' : ''">
                          <tr><td><h5 class="card-title"><fai :icon="task.complete ? [ 'far', 'check-circle'] : [ 'far', 'circle']"></fai></h5></td><td style="width:10px;">&nbsp;</td><td><h5 class="card-title">{{task.title}}</h5></td></tr>
                          <tr><td></td><td style="width:10px;">&nbsp;</td><td><h6 class="card-subtitle mb-2"><fai :icon="[ 'far', 'hourglass']"></fai> {{task.timeDisplay}}</h6></td></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="completedEnrollments && completedEnrollments.length > 0">
      <div class="col-12">
        <div class="pt-3 pb-1 fw-bold fs-5">Past Classes</div>
        <div class="accordion" id="portalHomeCompleted">

          <div class="accordion-item" v-for="(enrollment) in completedEnrollments" :key="enrollment.id">
            <h2 class="accordion-header">
              <button class="accordion-button" :class="enrollment.id === currentExpandedEnrollmentId ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="'#enrollment-'+enrollment.id" aria-expanded="true" :aria-controls="'enrollment-'+enrollment.id" @click="$event.target.blur();setCurrentlyExpandedEnrollment(enrollment)">
                {{enrollment.courseTitle}}&nbsp;<span class="text-dark">(<span v-html="formatCourseDates(enrollment, enrollment.startDate,enrollment.endDate)"></span>)</span>
              </button>

              <div class="progress" role="progressbar" aria-label="tasks percent complete" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="height: 3px;">
                <div class="progress-bar bg-success" :style="'width:'+ enrollment.percentComplete+'%;'"></div>
              </div>
            </h2>
            <div :id="'enrollment-'+enrollment.id" class="accordion-collapse collapse" :class="enrollment.id === currentExpandedEnrollmentId ? 'show' : ''" data-bs-parent="#portalHome">
              <div class="accordion-body">

                <div><span class="class-start-header"><span v-html="daysUntilClassStarts(enrollment.startDate)"></span></span><span v-html="weeksUntilClassStarts(enrollment.startDate)"></span></div>
                <div>{{enrollment.courseOverview}}</div>

                <div class="pt-3 pb-3 fs-4" v-if="enrollment.allTasksComplete">Congrats! You have completed all tasks and are ready for your sailing class, great job {{student.firstName}}!</div>

                <div class="row" v-for="task in enrollment.tasks" :key="task.id" @click="loadTaskDetail(task, enrollment)" :style="task.complete ? 'opacity: .6':''">
                  <div class="col-12">
                    <div class="card mt-3 bg-light">
                      <div class="card-body" :class="task.hasDetail ? 'charterRodeBtn' : ''">
                        <div style="float: right;position: relative;top:1px;font-weight: bold;text-transform: uppercase;"><fai :icon="task.complete ? 'check' : 'exclamation-triangle'" :class="task.complete ? 'text-success' : 'text-danger'" /></div>
                        <table :class="task.complete ? 'text-success' : ''">
                          <tr><td><h5 class="card-title"><fai :icon="task.complete ? [ 'far', 'check-circle'] : [ 'far', 'circle']"></fai></h5></td><td style="width:10px;">&nbsp;</td><td><h5 class="card-title">{{task.title}}</h5></td></tr>
                          <tr><td></td><td style="width:10px;">&nbsp;</td><td><h6 class="card-subtitle mb-2"><fai :icon="[ 'far', 'hourglass']"></fai> {{task.timeDisplay}}</h6></td></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <teleport to="body">
      <div class="position-fixed bottom-0 end-0 p-3 cr-z-index-toast">
        <div id="homeTaskCompleteModalToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">Marked Complete!</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">This task has been marked as complete</div>
        </div>
      </div>
    </teleport>

    <courseScheduleModal
        ref="courseScheduleModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </courseScheduleModal>

    <aboutSchoolModal
        ref="aboutSchoolModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </aboutSchoolModal>

    <meetInstructorModal
        ref="meetInstructorModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </meetInstructorModal>

    <sailingResumeSetupModal
        ref="sailingResumeSetupModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </sailingResumeSetupModal>

    <sailingResumeVerificationModal
        ref="sailingResumeVerificationModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </sailingResumeVerificationModal>

    <asaAccountSetupModal
        ref="asaAccountSetupModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </asaAccountSetupModal>

    <courseTextbookModal
        ref="courseTextbookModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </courseTextbookModal>

    <courseHandoutModal
        ref="courseHandoutModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </courseHandoutModal>

    <contentBlockModal
        ref="contentBlockModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </contentBlockModal>

    <taskModal
        ref="taskModal"
        v-on:markTaskComplete="markTaskComplete"
        v-on:modalClose="taskModalClosed">
    </taskModal>

  </div>
</template>

<script>
import api from "../../api/api"
import {Toast} from "bootstrap";

export default {
  name: 'Home',
  data() {
    return {
      renderComponent: true,
      currentExpandedEnrollmentId: this.$store.state.currentExpandedEnrollmentId ? this.$store.state.currentExpandedEnrollmentId : '',
      student: this.$store.state.student ? this.$store.state.student : {},
      enrollments: this.$store.state.enrollments ? this.$store.state.enrollments : [],
      completedEnrollments: this.$store.state.completedEnrollments ? this.$store.state.completedEnrollments : [],
      school: this.$store.state.school ? this.$store.state.school : undefined,
      hasEnrollments: (this.$store.state.enrollments && this.$store.state.enrollments.length > 0) || (this.$store.state.completedEnrollments && this.$store.state.completedEnrollments.length > 0)
    }
  },
  mounted() {
    // check do see if we should default to expand the first enrollment?
    if ( this.currentExpandedEnrollmentId === '' ) {
      if ( this.enrollments && this.enrollments.length > 0 && this.enrollments[0].id ) {
        this.currentExpandedEnrollmentId = this.enrollments[0].id
      }
    }
  },
  methods: {
    async forceRender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Then, wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add MyComponent back in
      this.renderComponent = true;
    },
    setCurrentlyExpandedEnrollment(anEnrollment) {
      if ( this.$store.state.currentExpandedEnrollmentId && this.$store.state.currentExpandedEnrollmentId === anEnrollment.id ) {
        this.$store.state.currentExpandedEnrollmentId = ''
        this.currentExpandedEnrollmentId = ''
      } else {
        this.$store.state.currentExpandedEnrollmentId = anEnrollment.id
        this.currentExpandedEnrollmentId = anEnrollment.id
      }
    },
    loadTaskDetail(task, enrollment) {
      this.$store.state.currentTask = task
      this.$store.state.currentEnrollment = enrollment
      if ( task.type === 'courseSchedule') {
        this.$refs.courseScheduleModal.activateModal()
      } else if ( task.type === 'aboutSchool') {
        this.$refs.aboutSchoolModal.activateModal()
      } else if ( task.type === 'meetInstructor') {
        this.$refs.meetInstructorModal.activateModal()
      } else if ( task.type === 'sailingResume') {
        this.$refs.sailingResumeSetupModal.activateModal()
      } else if ( task.type === 'sailingResumeVerification') {
        this.$refs.sailingResumeVerificationModal.activateModal()
      } else if ( task.type === 'asaAccount') {
        this.$refs.asaAccountSetupModal.activateModal()
      } else if ( task.type === 'courseTextbook') {
        this.$refs.courseTextbookModal.activateModal()
      } else if ( task.type === 'courseHandout') {
        this.$refs.courseHandoutModal.activateModal()
      } else if ( task.type === 'contentBlock') {
        this.$refs.contentBlockModal.activateModal()
      } else if ( task.type === 'task') {
        this.$refs.taskModal.activateModal()
      }
    },
    markTaskComplete(task, data) {
      this.$store.state.activeModal = undefined
      this.$store.state.enrollments.forEach(function(anEnrollment) {
        if ( anEnrollment.tasks ) {
          anEnrollment.tasks.forEach(function(aTask) {
            if ( aTask.id === task.id ) {
              aTask.complete = true
              aTask.status = 'complete'
            } else if ( task.type === 'asaAccount' && aTask.type === 'asaAccount' ) {
              // set the task complete as it is the same and we only
              // need this once per user!
              aTask.complete = true
              aTask.status = 'complete'
            }
          })
          // now update percent complete...
          let hasIncompleteTasks = false
          let totalMinutesComplete = 0
          let totalMinutesForAllTasks = 0
          anEnrollment.tasks.forEach(function(aTask) {
            totalMinutesForAllTasks += parseInt(aTask.timeMinutes)
            if ( !aTask.complete ) {
              hasIncompleteTasks = true
            } else {
              totalMinutesComplete += parseInt(aTask.timeMinutes)
            }
          })
          if ( !hasIncompleteTasks ) {
            anEnrollment.allTasksComplete = true
          }
          anEnrollment.percentComplete =  Math.round((totalMinutesComplete / totalMinutesForAllTasks) * 100)
        }
      })
      // notify back end
      api.markTaskComplete(task.id, data).then(result => {
        if ( result && result.status && result.status === 'ok' ) {
          console.log('task marked complete on server with id:'+task.id)
        } else {
          // error
          console.log('server reported back error when marking task complete with id:'+task.id)
        }
      })
      let self = this
      self.forceRender()

      // have to wait for the re-render to complete before doing this....
      setTimeout(function() {
        let taskCompleteToast = new Toast(document.getElementById('homeTaskCompleteModalToast'))
        taskCompleteToast.show()
      }, 500)
    },
    taskModalClosed() {
      // TBD if we even need this hook...
      this.$store.state.activeModal = undefined
    },
    daysUntilClassStarts(startDate) {
      let startTime = new Date(startDate+ " 12:00:00")
      let now = new Date()
      let days = Math.round((startTime - now) / (1000 * 60 * 60 * 24));
      if ( days > 1 ) {
        return 'Your class starts in '+days+' days'
      } else if ( days == 1 ) {
        return 'Your class starts tomorrow'
      } else if ( days == 0 ) {
        return 'Your class starts today'
      } else {
        return 'Your class has completed'
      }
    },
    weeksUntilClassStarts(startDate) {
      let startTime = new Date(startDate+ " 12:00:00")
      let now = new Date()
      let numWeeks = Math.floor(((startTime - now) / (1000 * 60 * 60 * 24) /  7) + 0.2)
      let numDays = this.daysUntilClassStarts(startDate)
      if ( numWeeks > 1 ) {
        return ' (~'+numWeeks+' weeks).'
      } else if ( numWeeks == 1 ) {
        if ( numDays > 9 ) {
          return ' (less than two weeks).'
        } else {
          return '.'
        }
      } else {
        return '.'
      }
    },
    formatCourseDates(enrollment, startDate, endDate) {
      // trip.date is in format YYYY-MM-DD
      const monthsOfYear = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let tripStart = startDate.split('-')
      let tripEnd = endDate.split('-')
      if ( enrollment.courseDurationDays === 1 ) {
        return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2]
      } else {
        if (tripStart[0] === tripEnd[0]) { // years match
          if (tripStart[1] === tripEnd[1]) { // months match
            return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2] + '-' + tripEnd[2] + ', ' + tripStart[0]
          } else { // months mismatch
            return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2] + '-' + monthsOfYear[parseInt(tripEnd[1])] + ' ' + tripEnd[2] + ', ' + tripStart[0]
          }
        } else { // years mismatch...
          return monthsOfYear[parseInt(tripStart[1])] + ' ' + tripStart[2] + ', ' + tripStart[0] + '-' + monthsOfYear[parseInt(tripEnd[1])] + ' ' + tripEnd[2] + ', ' + tripEnd[0]
        }
      }
    },
  },
  components: {

  }
}
</script>

<style>

.class-start-header {
  font-weight: bold;
  font-size: 1.1em;
}
.course-title {
  font-weight: bold;
  font-size: 1.25em;
}

.card-subtitle {
  font-size: .85em!important;
}

.card-body {
  padding: .5em;
}

.taskLinkButton {
  font-size: 20px!important;
}
</style>