<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="aboutSchoolModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">About {{school.name}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>-->

              <div>
                <div style="text-align:center">
                  <div style="margin:0px auto;max-width:850px;">

                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div style="text-align:center"><img :src="school.logoUrl" style="max-width:400px;width: 100%;"><br/>&nbsp;<br/></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div style="max-width:850px;text-align:left;font-size: 20px;">
                            <div v-html="school.aboutUsDescription"></div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="d-grid gap-2">
                            <a :href="school.websiteUrl" class="btn btn-primary taskLinkButton" target="_blank" @click="$event.target.blur()">Visit Our Website</a>
                          </div>
                        </div>
                      </div>

                      <div class="row" v-for="(link,index) in school.links" :key="link.id" :class="index == 0 ? 'pt-5' : 'pt-1'">
                        <div class="col-12">
                          <div class="d-grid gap-2">
                            <a :href="link.link" class="btn btn-outline-secondary taskLinkButton" target="_blank" @click="$event.target.blur()">{{link.title}}</a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import {Modal} from "bootstrap"
export default {
  name: 'AboutSchoolModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      school: this.$store.state.school ? this.$store.state.school : {},
      markTaskCompleteCalled: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.aboutSchoolModal = new Modal(document.getElementById('aboutSchoolModal'))
      let aboutSchoolModalEL = document.getElementById('aboutSchoolModal')
      let self = this
      self.$store.state.activeModal = this.aboutSchoolModal
      aboutSchoolModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.aboutSchoolModal.toggle()
      this.currentTask = this.$store.state.currentTask
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.aboutSchoolModal.hide()
    },
  },
  components: {

  }
}
</script>

<style>

</style>
