<template>
  <div class="container">
    <div class="row">
      <h4>Sailing Resume Handler</h4>
      <div>Please stand by while we verify your request.</div>

      <div class="text-center fs-4 mt-4"><fai icon="spinner" spin/> Verifying your sailing resume on the Sailing Resume Blockchain ...</div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router"
import api from "../api/api";

export default {
  name: 'SailingResumeHandler',
  data() {
    return {
      sailorId: undefined
    }
  },
  mounted() {
    const route = useRoute()
    this.sailorId = route.params.sailorId
    let self = this
    if ( this.$store.state.studentId ) {
      api.getInfoForStudentId().then(userInfo => {
        if (userInfo && userInfo.student) {
          self.$store.commit('setStudentInfo', userInfo)
          // set sailing resume id.....
          self.$store.state.student.sailingResumeId = self.sailorId

          // was this a wallet verification?
          let awardCRT = false
          if ( localStorage.getItem("sailingResumeReturnParams") != undefined &&
              localStorage.getItem('sailingResumeVerificationPayload') != undefined ) {
            // we have a wallet verification!
            let reqParams = new URLSearchParams(localStorage.getItem("sailingResumeReturnParams"))
            if ( reqParams.has('sailorId') ) {
              self.$store.state.student.sailingResumeId = reqParams.get('sailorId')
            }
            let nonce = undefined
            if ( reqParams.has('nonce') ) {
              nonce = reqParams.get('nonce')
            }

            // load what we sent over....
            let requestPayload = JSON.parse(localStorage.getItem('sailingResumeVerificationPayload'))

            if ( requestPayload.nonce != nonce ) {
              // error!
              console.log('Error during wallet verification: nonce mismatch!')
              self.$store.state.student.sailingResumeId = undefined
              self.$router.push('/home')
            }
            // TODO: verify signature
            // let signature = undefined
            // if ( reqParams.has('signature') ) {
            //   signature = reqParams.get('signature')
            //   console.log('signature:'+signature)
            // }

            // clean up
            localStorage.removeItem("sailingResumeReturnParams")
            localStorage.removeItem('sailingResumeVerificationPayload')

            // finally as this was a connection vs. new account, award the CRT
            awardCRT = true
          }

          // now find a task to mark complete!
          let taskId = undefined
          self.$store.state.enrollments.forEach(function(anEnrollment) {
            anEnrollment.tasks.forEach(function(aTask) {
              if ( aTask.type === 'sailingResume' && aTask.status === 'incomplete') {
                if ( taskId == undefined ) {
                  taskId = aTask.id
                  aTask.complete = true
                  aTask.status = 'complete'
                }
              }
            })
          })
          api.markTaskComplete(taskId, {sailingResumeId: this.sailorId, awardCRT: awardCRT}).then(result => {
            if ( result && result.status && result.status === 'ok' ) {
              console.log('task marked complete on server with id:'+taskId)
              self.$router.push('/home')
            } else {
              // error
              console.log('server reported back error when marking task complete with id:'+taskId)
              self.$router.push('/home')
            }
          })
        } else {
          // failed login
          self.magicId = undefined
          self.$store.commit('clearAll')
          self.$router.push('/')
        }
      })
    } else {
      this.showLogin = true
    }
  },
  methods: {

  }
}
</script>

<style>

</style>

