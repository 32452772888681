<template>
  <nav v-if="showHeader" class="navbar navbar-expand-sm navbar-dark bg-primary sticky-top no-print">
    <div class="container-fluid">
      <router-link class="navbar-brand" v-on:click="collapseNavbarToggler" to="/home"><fai icon="home" fixed-width /></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon small-nav-toggle"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-md-0 mb-lg-0">
          <li class="nav-item"><router-link class="nav-link" v-on:click="collapseNavbarToggler" to="/getting-started"><fai icon="info-circle" fixed-width /> Getting Started</router-link></li>
          <li v-if="showFaqs" class="nav-item"><router-link class="nav-link" v-on:click="collapseNavbarToggler" to="/faqs"><fai :icon="[ 'far', 'question-circle']" fixed-width></fai> FAQs</router-link></li>
          <li v-if="showResources" class="nav-item"><router-link class="nav-link" v-on:click="collapseNavbarToggler" to="/resources"><fai icon="file-alt" fixed-width /> Resources</router-link></li>
          <li v-show="!isMaintenance" class="nav-item"><router-link class="nav-link"  v-on:click="collapseNavbarToggler" to="/profile"><fai icon="user-circle" fixed-width /> Profile</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/sign-out"><fai icon="sign-out-alt" fixed-width /> Sign Out</router-link></li>
        </ul>
        <span class="navbar-text">
<!--          <router-link class="cr-nav-link nav-link" v-on:click="collapseNavbarToggler" to="/wallet"><span class="badge bg-secondary bg-success">8 Tasks</span></router-link>-->
        </span>
      </div>
    </div>
  </nav>
  <router-view/>
  <div v-if="showHeader" class="pt-3">
    <div class="footerBlock" style="width: 100%;">
      <hr style="margin:0px;height: 0.5px;">
      <div style="font-size:10px;padding:10px;color:#aaa">@ Copyright 2023-{{copyYear}}, <a href="https://www.charterrode.com" style="color:#aaa;font-weight:normal;text-decoration: none;">Charter Rode</a>. Weigh anchor. Explore the world.</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      copyYear: new Date().getFullYear(),
    }
  },
  methods: {
    collapseNavbarToggler() {
      if ( window.getComputedStyle(document.querySelector("button.navbar-toggler")).display !== 'none' ) {
        let className = document.getElementById("navbarSupportedContent").className;
        if (className === 'navbar-collapse collapse show') {
          document.getElementById("navbarSupportedContent").className = 'navbar-collapse collapse'
        }
      }
    }
  },
  computed: {
    showHeader() {
      return this.$route.name != 'Root' && this.$route.name != 'Pricing' && this.$route.name != 'MagicLink'
    },
    showFaqs() {
      return this.$store.state.faqs != undefined
    },
    showResources() {
      return this.$store.state.resourceArticles != undefined
    },
    isMaintenance() {
      return this.$store.state.isMaintenance
    },
  }
}
</script>

<style>
body {
  color: #444;
}

.cr-nav-link {
  padding:0px!important;
}

.table-caption {
  color: #888;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.form-control {
  color: #555;
}

.charterRodeBtn:hover, .charterRodeLink:hover {
  cursor: pointer;
}

.charterRodeLink {
  color: #0d6efd;
  text-decoration:underline;
}


.dropdown-item {
  color: #444;
}

.small-nav-toggle {
  font-size: 14px!important;
}

.charterRodeHelpText {
  color: #aaa;
}

.cr-z-index-5 {
  z-index: 5000;
}

.cr-z-index-toast {
  z-index: 5000;
}

.disclaimer-text {
  font-size: 16px;
  text-align: center;
}

.disclaimer-text-left {
  font-size: 16px;
  text-align: left;
}

select {
  font-size: 16px!important;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-bottom: 0.125rem!important;
}

.cr-footer-container {
  text-align: center;
  font-size:10px;
  color: #bbb;
  margin-bottom:25px;
}
.cr-footer-links {
  color: #bbb;
}

/*a {*/
/*  text-decoration: none;*/
/*}*/

.charterRodeBtn:hover, .charterRodeLink:hover {
  cursor: pointer;
}
.charterRodeHelpText {
  color: #aaa;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: 0.125rem!important;
}

.footerBlock {
  color: #666666;
}
</style>
