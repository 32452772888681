<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>FAQs</h4>
        <div class="charterRodeHelpText">You have questions. We have answers. Tap or click the question below to expand the answer.</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="accordion" id="faqsList">
          <div v-for="faq in faqs" :key="faq.id" class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFaq-'+faq.id" aria-expanded="false" :aria-controls="'collapseFaq-'+faq.id" @click="$event.target.blur()">
                {{faq.question}}
              </button>
            </h2>
            <div :id="'collapseFaq-'+faq.id" class="accordion-collapse collapse" data-bs-parent="#faqsList">
              <div class="accordion-body">
                {{faq.answer}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row justify-content-center mt-5">
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
        <div style="display:inline-block;width:265px;margin:0 10px;">
          <div class="d-grid gap-2">
            <router-link class="btn btn-primary text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" to="/home">Back to My Tasks</router-link>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>

export default {
  name: 'GettingStarted',
  data() {
    return {
      faqs: this.$store.state.faqs,
    }
  },
  mounted() {
  },
  methods: {
  },
  components: {

  }
}
</script>

<style>

</style>
