import { createRouter, createWebHashHistory } from 'vue-router'

// the store
import store from '../store/store'
const theStore = store

// 'views' that are routes
import Root from '../views/Root.vue'
import GettingStarted from '../views/student/GettingStarted.vue'
import Home from '../views/student/Home.vue'
import Profile from '../views/student/Profile.vue'
import Faqs from '../views/student/FAQs.vue'
import Resources from '../views/student/Resources.vue'
import ViewResource from '../views/student/ViewResource.vue'
import SailingResumeHandler from '../views/SailingResumeHandler.vue'
import SignOut from '../views/SignOut.vue'
import Pricing from '../views/Pricing.vue'

const appSubDirectory = process.env.VUE_APP_CHARTER_RODE_APP_SUBDIRECTORY

// our routes
const routes = [
    { path: '/', component: Root, name: 'Root', meta: {requiresAuth: false}},
    { path: '/:magicId', component: Root, name: 'MagicLink', meta: {requiresAuth: false}},
    { path: '/profile', component: Profile, name: 'Profile', meta: {requiresAuth: false}},
    { path: '/sign-out', component: SignOut, name: 'SignOut', meta: {requiresAuth: false}},
    { path: '/pricing', component: Pricing, name: 'Pricing', meta: {requiresAuth: false}},
    { path: '/getting-started', component: GettingStarted, name: 'GettingStarted', meta: {requiresAuth: true}},
    { path: '/home', component: Home, name: 'Home', meta: {requiresAuth: true}},
    { path: '/faqs', component: Faqs, name: 'FAQs', meta: {requiresAuth: true}},
    { path: '/resources', component: Resources, name: 'Resources', meta: {requiresAuth: true}},
    { path: '/viewResource', component: ViewResource, name: 'ViewResource', meta: {requiresAuth: true}},
    { path: '/sailingResume/:sailorId', component: SailingResumeHandler, name: 'SailingResumeHandler', meta: {requiresAuth: true}}
]

const routerHistory = createWebHashHistory()
const router = createRouter({
    history: routerHistory,
    base: appSubDirectory,
    strict: true,
    routes: routes,
    scrollBehavior () {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({left:0, top:0})
            }, 10)
        })
    }
})

// // remove trailing slashes
router.beforeEach((to) => {
    if ( theStore.state.activeModal ) {
        theStore.state.activeModal.hide()
        theStore.state.activeModal = undefined
        return false
    }
    if ( window.location && window.location.pathname && window.location.pathname.length > 1 ) {
        // is this the sailingResumeHandler redirect?
        if ( window.location.pathname === '/sailingResume' ) {
            let sailorId = window.location.search.substring(window.location.search.indexOf('?sailorId=', window.location.search.length)).split('=')[1]
            if ( sailorId.indexOf('&') > 0 ) {
                // need to get rid of that...likely returning from wallet verify with '&nonce'
                sailorId = sailorId.substring(0,sailorId.indexOf('&'))
            }
            localStorage.setItem("sailingResumeReturnParams", window.location.search)
            window.location = window.location.origin + "/#/sailingResume/" + sailorId
        } else {
            // rewrite this to see if we can use it to lookup magicId
            window.location = window.location.origin + "/#/" + window.location.pathname.substring(1, window.location.pathname.length)
        }
    } else {
        if ( to.name != 'Root' && to.name !='MagicLink' && to.name != 'Maintenance' && to.name != 'SailingResumeHandler' && to.name != 'Pricing' ) {
            // are we setup and logged in?
            if ( store != undefined && store.state != undefined && store.state.loggedIn ) {
                // next() // go to wherever I'm going
            } else {
                // fall back to gather data from server for this session if possible,
                // otherwise force a magic link login
                return {name: 'Root'}
            }
        }
    }
})

export default router