<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="meetInstructorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{currentTask.title}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>-->

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">



                  <div v-if="currentEnrollment.instructor" class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="text-align:center"><img :src="currentEnrollment.instructor.headshotUrl" style="max-width:200px;width: 100%;"><br/>&nbsp;<br/></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div><div class="fw-bold fs-3"><span v-if="currentEnrollment.instructor.isUSCGCaptain">Captain&nbsp;</span>{{currentEnrollment.instructor.firstName}} {{currentEnrollment.instructor.lastName}}</div></div>
                        <div style="max-width:850px;text-align:left;font-size: 20px;">
                          <div>
                            <table>
                              <tr v-if="currentEnrollment.instructor.credentials">
                                <td class="fw-bold" style="vertical-align: top;">Credentials:</td><td style="width: 10px;">&nbsp;</td><td><span v-html="currentEnrollment.instructor.credentials"></span></td>
                              </tr>
                              <tr v-if="currentEnrollment.instructor.seaMiles">
                                <td class="fw-bold" style="vertical-align: top;">Sea Miles:</td><td style="width: 10px;">&nbsp;</td><td>{{currentEnrollment.instructor.seaMiles}}</td>
                              </tr>
                              <tr v-if="currentEnrollment.instructor.favoriteDrink">
                                <td class="fw-bold" style="vertical-align: top;">Fav Bev:</td><td style="width: 10px;">&nbsp;</td><td>{{currentEnrollment.instructor.favoriteDrink}}</td>
                              </tr>
                              <tr v-if="currentEnrollment.instructor.favoriteQuote">
                                <td class="fw-bold" style="vertical-align: top;">Quote:</td><td style="width: 10px;">&nbsp;</td><td>"{{currentEnrollment.instructor.favoriteQuote}}"</td>
                              </tr>
                            </table>
                          </div>
                          <div class="pt-3" v-html="currentEnrollment.instructor.bio"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="max-width:850px;text-align:left;font-size:20px;">
                          <div v-if="currentEnrollment">
                            <div v-html="currentTask.content"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>

import {Modal} from "bootstrap";

export default {
  name: 'MeetInstructorModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      currentEnrollment: this.$store.state.currentEnrollment ? this.$store.state.currentEnrollment : { instructor: {}},
      markTaskCompleteCalled: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.meetInstructorModal = new Modal(document.getElementById('meetInstructorModal'))
      let meetInstructorModalEL = document.getElementById('meetInstructorModal')
      let self = this
      self.$store.state.activeModal = this.meetInstructorModal
      meetInstructorModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.meetInstructorModal.toggle()
      this.currentTask = this.$store.state.currentTask
      this.currentEnrollment = this.$store.state.currentEnrollment
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.meetInstructorModal.hide()
    },
  },
  components: {

  }
}
</script>

<style>

</style>
