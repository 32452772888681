
import { createStore } from 'vuex'

// Create a new store instance
// critical components are backed by the localStorage so that if someone
// clicks the refresh screen then the right thing happens and we don't
// loose all of the info
const store = createStore({
    state () {
        return {
            studentId: localStorage.getItem('studentId') ? localStorage.getItem('studentId') : undefined,
            loggedIn: false,
            isMaintenance: false,
            currentArticle: undefined,
            currentTask: undefined,
            currentEnrollment:undefined,
            currentExpandedEnrollmentId: undefined,
            resourceArticles: undefined,
            faqs: undefined,
            student: undefined,
            school: undefined,
            enrollments: undefined,
            completedEnrollments: undefined
        }
    },
    mutations: {
        setStudentInfo(state, studentInfo) {
            if ( studentInfo ) {
                state.loggedIn = true
                if ( studentInfo.resourceArticles ) {
                    state.resourceArticles = studentInfo.resourceArticles
                }
                if ( studentInfo.faqs ) {
                    state.faqs = studentInfo.faqs
                    // sort!
                    if ( state.faqs ) {
                        // alphabetize them!
                        state.faqs.sort(function(a,b) {
                            if (a.displayOrder > b.displayOrder) return 1
                            if (a.displayOrder < b.displayOrder) return -1
                            return 0
                        })
                    }
                }
                if ( studentInfo.student ) {
                    state.student = studentInfo.student
                    localStorage.setItem('studentId', studentInfo.student.id)
                }
                if ( studentInfo.school ) {
                    state.school = studentInfo.school
                }
                if ( studentInfo.enrollments ) {
                    // sort the student enrollments
                    studentInfo.enrollments.sort((a,b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))

                    // create an active and completed list....
                    let completed = []
                    let active = []

                    let now = new Date()
                    studentInfo.enrollments.forEach(function (anEnrollment) {
                        let startTime = new Date(anEnrollment.startDate+ " 12:00:00")
                        let days = Math.round((startTime - now) / (1000 * 60 * 60 * 24));
                        if ( days < -14 ) {
                            completed.push(anEnrollment)
                        } else {
                            active.push(anEnrollment)
                        }
                    })

                    if ( active.length > 0 ) {
                        state.enrollments = active
                    } else {
                        state.enrollments = undefined
                    }

                    if ( completed.length > 0 ) {
                        state.completedEnrollments = completed
                    } else {
                        state.completedEnrollments = undefined
                    }
                }
            }
        },
        clearAll(state) {
            state.loggedIn = false
            state.resourceArticles = undefined
            state.faqs = undefined
            state.student = undefined
            state.school = undefined
            state.enrollments = undefined
            state.completedEnrollments = undefined
            state.studentId = undefined
            state.instructorId = undefined
            localStorage.removeItem('studentId')
            localStorage.removeItem('instructorId')
        }
    },
    getters: {

    }
})

export default store