<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4><fai :icon="[ 'far', 'arrow-alt-circle-left']" class="charterRodeBtn text-primary" @click="backToResources()"></fai>&nbsp;{{article.title}}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="cr-art-article-content">
          <p v-html="article.description"></p>
          <div v-html="article.body"></div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
        <div style="display:inline-block;width:265px;margin:0 10px;">
          <div class="d-grid gap-2">
            <router-link class="btn btn-primary text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" to="/resources">Back to Resources</router-link>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: 'ViewResources',
  data() {
    return {
      article: this.$store.state.currentArticle ? this.$store.state.currentArticle : {}
    }
  },
  mounted() {
    if ( this.article == undefined || this.article.title == undefined ) {
      this.backToResources()
    }
  },
  methods: {
    backToResources() {
      this.$router.push('/resources')
    }
  },
  components: {

  }
}
</script>

<style>

.cr-art-article-content {
  font-size: 18px
}
</style>
