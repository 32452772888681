<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="sailingResumeVerificationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Sailing Resume Verification</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">This is the sailing resume verification widget.</div>-->

              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12">
                        <div style="text-align:center"><a href="https://www.charterrode.com" target="_blank"><img src="https://cdn.soflows.app/srb/SRBLogoHorizontal.png" style="max-width:400px;width: 100%;"></a><br/>&nbsp;<br/></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div style="max-width:850px;text-align:left;font-size:20px;font-family:Nunito,Sans-serif;">
                          <div v-if="verifyingExperience">
                            <div class="text-center fs-4"><fai icon="spinner" spin/> Verifying your sailing resume on the Sailing Resume Blockchain ...</div>
                          </div>
                          <div v-if="!currentTask.complete && !showMissingMinimumExperience && !verifyingExperience">
                            <p>This class requires a minimum of <span class="fw-bold">{{currentTask.minimumHours}} hours</span> logged as either captain or crew on your <a href="https://www.charterrode.com/article/sailing-resume" target="_blank">sailing resume</a>.</p>
                            <p>If you have logged this experience, including entries logged from prior ASA classes, then you may use the button below to verify your experience from the <a href="https://www.charterrode.com" target="_blank">Sailing Resume Blockchain</a>.</p>
                            <p>Before you proceed, be sure that <a href="https://app.charterrode.com/#/resume" target="_blank">your sailing resume</a> on the Sailing Resume Blockchain has the minimum requirements stated above.</p>
                            <p>This task will automatically complete when you finish verifying your experience using the button below.</p>
                          </div>
                          <div v-if="currentTask.complete && !verifyingExperience">
                            <p>This class requires a minimum of <span class="fw-bold">{{currentTask.minimumHours}} hours</span> logged as either captain or crew on your sailing resume.</p>
                            <p>Congratulations! Your experience has been verified on the <a href="https://www.charterrode.com" target="_blank">Sailing Resume Blockchain</a> and you meet the qualification requirements to take this class!</p>
                          </div>
                          <div v-if="!hasSailingResume && !verifyingExperience">
                            <p class="fw-bold">You may not complete this task until you have setup your sailing resume.</p>
                          </div>
                          <div v-show="showMissingMinimumExperience && !verifyingExperience">
                            <p>This class requires a minimum of <span class="fw-bold">{{currentTask.minimumHours}} hours</span> logged as either captain or crew on your <a href="https://www.charterrode.com/article/sailing-resume" target="_blank">sailing resume</a>.</p>
                            <p>Sorry. Your Sailing Resume does not meet the minimum requirements. You have accumulated <span class="fw-bold">a total of {{totalHoursVerified}} hours</span> of sailing (not powerboat) experience. Check back when you have spent more time on the water.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="!currentTask.complete && !verifyingExperience">
                      <div class="col-12">
                        <div class="d-grid gap-2">
                          <a class="btn btn-primary taskLinkButton" :class="!hasSailingResume ? 'disabled' : ''" @click="$event.target.blur();verifyExperience()" >Verify My Experience</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>

import {Modal} from "bootstrap";
import api from "../../api/api";

export default {
  name: 'SailingResumeVerificationModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      student: this.$store.state.student ? this.$store.state.student : {},
      hasSailingResume: false,
      markTaskCompleteCalled: false,
      showMissingMinimumExperience: false,
      totalHoursVerified: undefined,
      verifyingExperience: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.sailingResumeVerificationModal = new Modal(document.getElementById('sailingResumeVerificationModal'))
      let viewCourseScheduleEL = document.getElementById('sailingResumeVerificationModal')
      let self = this
      self.$store.state.activeModal = this.sailingResumeVerificationModal
      viewCourseScheduleEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.sailingResumeVerificationModal.toggle()
      this.currentTask = this.$store.state.currentTask
      this.student = this.$store.state.student
      this.hasSailingResume = this.student.sailingResumeId != undefined && this.student.sailingResumeId.length > 0
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.sailingResumeVerificationModal.hide()
    },
    verifyExperience() {
      // read their resume from the blockchain and then see if they have the experience this task requires.
      let self = this
      self.verifyingExperience = true
      self.showMissingMinimumExperience = false
      self.totalHoursVerified = undefined
      api.lookupSailingResumeFor(this.student.sailingResumeId).then(result => {
        let hasSufficientExperienceLogged = false
        if ( result && result.sailorId === self.student.sailingResumeId ) {
          // start the verification process...
          let rawTxs = undefined
          if ( result && result.txs ) {
            rawTxs = result.txs
          }
          if ( !rawTxs || rawTxs.length <= 0 ) {
            // no experience
          } else {
            // run through and look for 'sailing' txs and add up thr hours...
            let totalSailingHoursLogged = 0
            rawTxs.forEach(function(aTx) {
              if ( aTx.input && aTx.input.log && aTx.input.log.trip && aTx.input.log.trip.entries ) {
                if ( aTx.input.log.trip.entries[0].vessel && aTx.input.log.trip.entries[0].vessel.propulsion === 'sail' ) {
                  aTx.input.log.trip.entries.forEach(function(anEntry) {
                    totalSailingHoursLogged += parseFloat(anEntry.durationHours)
                  })
                }
              }
            })

            // now do the check!
            if ( totalSailingHoursLogged >= parseFloat(self.currentTask.minimumHours) ) {
              hasSufficientExperienceLogged = true
            } else {
              self.totalHoursVerified = totalSailingHoursLogged.toFixed(2)
            }
          }
        }

        self.verifyingExperience = false
        if ( hasSufficientExperienceLogged ) {
          // mark task complete
          self.markTaskComplete()
        } else {
          // notify the user that they do not have sufficient experience yet.
          self.showMissingMinimumExperience = true
        }
      })
    }
  },
  components: {

  }
}
</script>

<style>

</style>
