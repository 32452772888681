<template>
  <div>

    <teleport to="body">
      <!-- Modal -->
      <div class="modal fade" id="courseTextbookModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{currentTask.title}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
<!--              <div class="pb-3 charterRodeHelpText">Once you are finished reviewing, use the Mark Complete button at the bottom of the screen to mark this task complete in the app. Use the Close button to close this task without marking complete.</div>-->


              <div style="text-align:center">
                <div style="margin:0px auto;max-width:850px;">

                  <div class="container-fluid" v-if="currentEnrollment.courseInfo">
                    <div class="row">
                      <div class="col-12">
                        <div style="text-align:center"><img :src="currentEnrollment.courseInfo.textbook.imageSrc" style="max-width:400px;width: 100%;"><br/>&nbsp;<br/></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div><div class="fw-bold fs-3">{{currentEnrollment.courseInfo.textbook.title}}</div></div>
                        <div style="max-width:850px;text-align:left;font-size:20px;">
                          <div v-html="currentEnrollment.courseInfo.textbook.description"></div>
                          <p class="pt-3 fw-bold">Before you may being your class, YOU MUST complete the following:</p>
                          <ol>
                            <li>Read the entire textbook cover to cover.</li>
                            <li>Complete all of the end of chapter assessments.</li>
                            <li>Be confident that you know all of the materials to take the written test during the live classroom class.</li>
                          </ol>
                          <div>Only mark this task complete if you have completed all of the above steps!</div>
                          <div class="pt-3" v-html="currentEnrollment.courseInfo.textbook.purchaseOptions"></div>

                          <div v-if="currentTask.content" v-html="currentTask.content"></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-outline-success" v-on:click="markTaskComplete" v-if="!currentTask.complete">Mark Complete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>

import {Modal} from "bootstrap";

export default {
  name: 'CourseTextbookModal',
  emits: ['markTaskComplete','modalClose'],
  data() {
    return {
      currentTask: this.$store.state.currentTask ? this.$store.state.currentTask : {},
      currentEnrollment: this.$store.state.currentEnrollment ? this.$store.state.currentEnrollment : {},
      markTaskCompleteCalled: false
    }
  },
  mounted() {
  },
  methods: {
    activateModal() {
      this.courseTextbookModal = new Modal(document.getElementById('courseTextbookModal'))
      let courseTextbookModalEL = document.getElementById('courseTextbookModal')
      let self = this
      self.$store.state.activeModal = this.courseTextbookModal
      courseTextbookModalEL.addEventListener('hidden.bs.modal', function () {
        if ( !self.markTaskCompleteCalled ) {
          self.$emit('modalClose')
        }
      })
      this.courseTextbookModal.toggle()
      this.currentTask = this.$store.state.currentTask
      this.currentEnrollment = this.$store.state.currentEnrollment
    },
    markTaskComplete() {
      this.markTaskCompleteCalled = true
      this.$emit('markTaskComplete', this.currentTask)
      this.courseTextbookModal.hide()
    },
  },
  components: {

  }
}
</script>

<style>

</style>
