<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4>Getting Started</h4>
        <div class="charterRodeHelpText">Welcome to the {{school.name}} student app!</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div style="font-size:20px;">
          <p>Hi {{student.firstName}},</p>
          <p>Welcome to {{school.name}} and thank you for choosing us for your sailing lesson. We are committed to not only providing you with the best sailing instruction possible, but also providing a range of opportunities to help you maintain the knowledge and skills you achieve during your sailing lesson.</p>
          <p>Being properly prepared is extremely important to your success in this course, and this app is designed to help you with that preparation. Please be sure to go through each of the sections carefully.</p>
          <p>In the <router-link to="/home">following sections on the app</router-link>, you will find details about:
          <ul>
            <li>Your class dates/times and where we are located</li>
            <li>Contact information</li>
            <li>Information about your sailing lesson and what happens each day</li>
            <li>What to bring/not bring</li>
            <li>Getting set up with your ASA ID and your Sailing Resume</li>
            <li>Preparing for class</li>
            <li>Instructor profiles</li>
            <li>General information about {{school.name}}</li>
          </ul></p>
          <div class="row mt-4 mb-4">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
              <div style="display:inline-block;width:265px;margin:0 10px;">
                <div class="d-grid gap-2">
                  <router-link class="btn btn-primary text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" to="/home">Let's Get Started!</router-link>
                </div>
              </div>
            </div>
          </div>
          <p>If you have any questions, you may reach us at <a :href="'mailto:'+school.schoolEmail">{{school.schoolEmail}}</a> or call us at <a :href="'tel:'+school.schoolPhone">{{school.schoolPhone}}</a>.</p>
          <p>Welcome Aboard!</p>
          <div v-html="school.gettingStartedSignature"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'GettingStarted',
  data() {
    return {
      school: this.$store.state.school,
      student: this.$store.state.student,
    }
  },
  mounted() {
  },
  methods: {
  },
  components: {

  }
}
</script>

<style>

</style>
